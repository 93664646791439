@import "@/styles/index.scss";

.about {
    .flexRow {
        display: flex;
        flex-direction: row;
    }

    .header {
        height: 120px;
        width: 80%;
        padding: 0 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        background-color: #FFFFFF;
        z-index: 999;

        .headerTitle {
            font-weight: 650;
            font-size: 2rem;
        }

        .headerTab {
            align-items: center;
            justify-content: flex-end;

            .headerTabInner {
                font-size: 1rem;
                margin-left: 2rem;
                margin-right: 0.5rem;
                cursor: pointer;
                width: 6rem;
                height: 2.5rem;
                border-radius: 2rem;
                line-height: 2.5rem;
            }

            .selected {
                background-color: #36a9ce;
                color: #FFFFFF;
            }
        }
    }

    .headerImg {
        width: 100%;
    }

    .aboutContent {
        display: flex;
        flex-direction: column;
        align-items: center;

        .aboutTitle {
            color: #00a9ac;
            font-size: 2.5rem;
            margin: 4rem 0 1rem 0;
            width: 78rem;
            text-align: start;
            font-weight: bold;
        }

        .aboutBody {
            color: #626262;
            font-size: 1.1rem;
            width: 78rem;
            line-height: 3rem;
            text-align: start;
        }

        .imgOuter {
            margin-top: 3rem;
            width: 78rem;
            display: flex;
            justify-content: space-between;
        }

        .cooperateTitle {
            font-weight: bold;
            font-size: 1.3rem;
            margin-top: 3rem;
            text-align: center;
            width: 78rem;
        }

        .cooperateOuter {
            display: flex;
            width: 78rem;
            flex-direction: row;
            justify-content: center;
            margin: 0.5rem 0 2rem 0;

            .cooperateLabel {
                font-size: 1.3rem;
                font-weight: bold;
                margin-left: 0.5rem;
                height: 2rem;
                line-height: 2rem;
            }

            .cooperateText {
                font-size: 1.3rem;
                height: 2rem;
                line-height: 2rem;
                font-weight: 400;
            }
        }

    }

    .bottomOuter {
        background-color: #212832;
        height: 14rem;
        color: #CACACA;
        display: flex;
        flex-direction: column;
        align-items: center;

        .bottomContent {
            display: flex;
            flex-direction: row;
            margin-top: 5rem;
            width: 100%;
            justify-content: center;

            .bottomIcon {
                font-size: 1.2rem;
                margin-right: 0.5rem;
            }

            .contentText {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 12%;

            }
        }

        .bottomName {
            margin-top: 3rem;
        }
    }
}
